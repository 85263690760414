* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  --primary: #fff;
  --primary-dark: #f5f5f5;
  --font-title: #000;
  --font-color: #696969;
  --dark: #666;
  --primary-darker: #9e9e9e;
  --footer-background: #000;
  --primary-main: #fafafa;
  --primary-button: #fa4f00;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

#root {
  min-height: calc(100vh - var(--bodyPaddinTop));
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;
  justify-content: center;
}
